import clsx from "clsx";
import { B4BackgroundContext, B4BackgroundType, useKeyUp, useOutsideClick, useScrollLock } from "./utils";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import { B4Text, B4TextBig } from "./text";
import { B4Button, B4ButtonColor, B4IconButton } from "./button";
import { MdClose } from "react-icons/md";
import { B4SpaceHorizontal, B4SpaceVertical } from "./layout";
import { useTranslation } from "react-i18next";
import { B4Bubble, B4BubbleColor } from "./bubble";

export enum B4ModalPosition {
  TOP,
  MIDDLE,
  BOTTOM
}

interface B4ModalProps {
  children: ReactNode,
  open: boolean
  required?: boolean,
  full?: boolean,
  onClose?: () => void,
  position?: B4ModalPosition,
  padding?: boolean
}

export const B4Above = ({children, className = 'z-[53]'}) => (
    <div className={clsx('w-full', className)}>{children}</div>
)

export const B4ModalTitle = ({text, onClose = null}) => (
  <div className={clsx('flex items-start',{
    'justify-between': onClose,
    'justify-center': !onClose,
  })}>
    <B4TextBig>{text}</B4TextBig>
    { onClose && <B4IconButton onClick={onClose}><MdClose/></B4IconButton> }
  </div>
)

export const B4Modal = ({children, open, onClose = null, position = B4ModalPosition.MIDDLE, full = false, padding = true}: B4ModalProps) => {
  const { bgType } = useContext(B4BackgroundContext)
  const ref = useOutsideClick(onClose)
  useKeyUp('Escape', onClose)
  const [hidden, setHidden] = useState(!open)

  useScrollLock(!open)

  const [styles, api] = useSpring(() => ({
    from: { scaleY: 0 },
  }))

  const [bgStyles, bgApi] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  const [bgStyles2, bgApi2] = useSpring(() => ({
    from: { backdropFilter: 'blur(0px)' },
  }))


  useEffect(() => {
    const CONFIG_OPEN = {
      config: { 
        duration: 500,
        easing: easings.easeOutQuart
      }
    }
  
    const CONFIG_CLOSE = {
      config: { 
        duration: 500
      }
    }
    
    let timeout = null
    if (open) {
      api.start({
        to: { scaleY: 1 },
        ...CONFIG_OPEN
      })
      bgApi.start({
        to: {opacity: 0.9},
        ...CONFIG_OPEN
      })
      bgApi2.start({
        to: {backdropFilter: 'blur(2px)'},
        ...CONFIG_OPEN
      })
      setHidden(false)
    } else {
      api.start({
        to: { scaleY: 0 },
        immediate: true
      })
      bgApi.start({
        to: {opacity: 0},
        immediate: true,
        ...CONFIG_CLOSE
      })
      bgApi2.start({
        to: {backdropFilter: 'blur(0px)'},
        immediate: true,
        ...CONFIG_CLOSE
      })
      timeout = setTimeout(() => setHidden(true), 500)
    }

    return () => clearTimeout(timeout)
  }, [open, api, bgApi, bgApi2])

  return hidden ? null : (
    <>
      <animated.div style={bgStyles2} className={clsx('z-[51] fixed inset-0')} />
      <animated.div style={bgStyles} className={clsx('z-[52] fixed inset-0', {
        'bg-b4-bg-dark': bgType === B4BackgroundType.DARK,
        'bg-b4-bg-light': bgType === B4BackgroundType.LIGHT,
      })} />
      <div className={clsx("z-[54] fixed inset-0 flex", {
        'items-start': position === B4ModalPosition.TOP,
        'items-center': position === B4ModalPosition.MIDDLE,
        'items-end': position === B4ModalPosition.BOTTOM,
      })}>
        <div className={clsx('w-full flex justify-center', {
          
        })}>
          <animated.div style={styles} ref={ref} className={clsx('max-h-dvh overflow-y-auto', {
            'origin-top': position === B4ModalPosition.TOP,
            'origin-bottom': position === B4ModalPosition.BOTTOM,
            'w-b4-max max-w-full': !full,
            'w-full': full,
            'p-3': padding,
          })}>
            {children}
          </animated.div>
        </div>
      </div>
    </>
  )
}

export const B4ModalConfirm = ({open, onCancel = null, onOk, preTitle = null, title = null, html = null, lblOk, lblCancel = null, children = null, timeout = false}) => (
  <B4Modal open={open} onClose={() => onCancel && onCancel()}><B4Bubble>
    <B4SpaceVertical>
      { preTitle }
      { title && <B4ModalTitle text={title} /> }
      { html && <B4Text html={html} /> }
      { children}
      <B4Button onClick={onOk} timeout={timeout}>{lblOk}</B4Button>
      { lblCancel && <B4Button color={B4ButtonColor.BLUE} onClick={() => onCancel && onCancel()}>{lblCancel}</B4Button> }
    </B4SpaceVertical>
  </B4Bubble></B4Modal>
)

export const B4ModalConfirmYesNo = ({open, onCancel, onOk, title, html}) => {
  const { t } = useTranslation()
  return (
    <B4ModalConfirm open={open} onCancel={onCancel} onOk={onOk} title={title} html={html} lblOk={t('txtYes')} lblCancel={t('txtNo')} />
  )
}

export enum B4ModalCreateState {
  IDLE,
  CREATE,
  CONFIRM
}
interface B4ModalCreateProps {
  state: B4ModalCreateState,
  onCancel: () => void,
  onSave: () => void,
  onConfirm: () => void,
  confirmTitle?: string,
  confirmHtml: string,
  confirmLblOk: string,
  children: ReactNode,
  title?: string,
  loading: boolean
}
export const B4ModalCreate = ({state, onCancel, onSave, onConfirm, confirmTitle = null, confirmHtml, confirmLblOk, children, title = null, loading}: B4ModalCreateProps) => {
  const { t } = useTranslation()

  return (
    <>
      <B4Modal open={state === B4ModalCreateState.CREATE} onClose={() => onCancel()} position={B4ModalPosition.MIDDLE}>
        <B4Bubble color={B4BubbleColor.GREEN_NEON}>
          <B4SpaceVertical>
            { title && <B4ModalTitle text={title} /> }
            { children }
            <B4SpaceHorizontal>
              <B4Button color={B4ButtonColor.BLUE} onClick={() => onCancel()} disabled={loading}>{t('txtCancel')}</B4Button>
              <B4Button color={B4ButtonColor.BLUE} onClick={() => onSave()} disabled={loading}>{t('txtOk')}</B4Button>
            </B4SpaceHorizontal>
          </B4SpaceVertical>
        </B4Bubble>
      </B4Modal>
      <B4ModalConfirm
        open={state === B4ModalCreateState.CONFIRM}
        onOk={() => onConfirm()}
        title={confirmTitle}
        html={confirmHtml}
        lblOk={confirmLblOk}
      /> 
    </>
  )
}