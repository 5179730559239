/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { animated, useSpring } from '@react-spring/web'
import { ReactNode, memo, useEffect, useState } from "react";
import clsx from "clsx";

interface B4CountdownProps {
  steps: ReactNode[],
  onEnd?: () => void,
  className?: string
}

export const B4Countdown = ({steps, onEnd = null, className = null}: B4CountdownProps) => {
  const [currentStep, setCurrentStep] = useState(0)

  const [stylesScale, apiScale] = useSpring(() => ({
    from: {scale: 0},
  }), [])


  const [stylesTurner, apiTurner] = useSpring(() => ({
    from: {rotate: 0},
  }), [])

  useEffect(() => {
    apiScale.start({
      from: {scale: 0},
      to: { scale: 1 },
      config: { duration: 400 }
    })

    apiTurner.start({
      from: {rotate: 0},
      to: {rotate: 360},
      config: { duration: 2000 }
    })

    const timeoutScale = setTimeout(() => {
      apiScale.start({
        from: {scale: 1},
        to: { scale: 0 },
        config: { duration: 400 }
      })
    }, 1600)

    const timeout = setTimeout(() => {
      if (currentStep >= steps.length - 1) {
        onEnd && onEnd()
      } else {
        setCurrentStep(currentStep + 1)
      }
    }, 2000);
    return () => {
      clearTimeout(timeout)
      clearTimeout(timeoutScale)
    };
  }, [currentStep, apiScale, onEnd, steps.length]);
  return (
    <div className={clsx('flex flex-col items-center justify-around', className)}>
      <div className="relative w-max">
        <animated.div style={stylesTurner} className="w-52 h-52 bg-b4-secondary p-3 rounded-full" css={css`
          mask:
              conic-gradient(#0000,#000),
              linear-gradient(#000 0 0) content-box;
          mask-composite: source-out;
          mask-composite: subtract;
        `}/>
        <div className="absolute inset-0">
          <div className="w-full h-full flex justify-center items-center">
            <animated.div style={stylesScale}>
              { steps[currentStep] }
            </animated.div>
          </div>
        </div>
      </div>
      <div className="h-48 w-48 flex justify-center items-center" style={{background: 'radial-gradient(#FFFFFF30, #FFFFFF00 70%)'}}>
        <animated.div className='text-white text-7xl font-bold' style={stylesScale}>
          <div>{steps.length - currentStep}</div>
        </animated.div>
      </div>
    </div>
  )
}

const custRandom = (max) => Math.floor(Math.random() * max)

const BackgroundAnimationCircle = memo(() => {

  const [circleSize] = useState(4 + custRandom(10))
  const [radius] = useState(custRandom(Math.max(window.innerWidth, window.innerHeight) / 2))
  const [ellipsisDelta] = useState(custRandom(200))
  const [duration] = useState(60000 + custRandom(40000))
  const [direction] = useState(custRandom(2) * 2 - 1)
  const [startAngle] = useState(custRandom(360))
  const [corner] = useState(Math.floor(Math.random() * 4))


  const circleMove = keyframes`
    from {
      transform: translateX(${ellipsisDelta}px) rotate(0deg);
    }

    50% {
      transform: translateX(-${ellipsisDelta}px) rotate(${direction * 180}deg);
    }

    to {
      transform: translateX(${ellipsisDelta}px) rotate(${direction * 360}deg);
    }
  `

  const counterMove = keyframes`
    from {
      transform: translateX(${radius}px) rotate(${-startAngle}deg);
    }

    50% {
      transform: translateX(${radius}px) rotate(${-startAngle - direction * 180}deg);
    }

    to {
      transform: translateX(${radius}px) rotate(${-startAngle - direction * 360}deg);
    }
  `


  return (
    <div style={{
      transform: `rotate(${startAngle}deg)`
    }}>
      <div className="absolute" css={css`
        width: ${2 * circleSize}px;
        height: ${circleSize}px;
        animation: ${circleMove} ${duration}ms linear infinite;
      `}>
        <div className={clsx("w-full h-full mix-blend-screen bg-b4-question opacity-30", {
          'rounded-tl-lg': corner !== 0,
          'rounded-tr-lg': corner !== 1,
          'rounded-bl-lg': corner !== 2,
          'rounded-br-lg': corner !== 3,
        })} css={css`
          animation: ${counterMove} ${duration}ms linear infinite;
          // background-image: radial-gradient(#49FFA81e, #49FFA82e);
        `} />
      </div>
    </div>
  )
})

const BackgroundAnimation = memo(() => (
  <div className="relative w-full h-full">{
    Array.from(Array(50).keys()).map(i => <div key={i} className="absolute inset-0 flex justify-center items-center"><BackgroundAnimationCircle /></div>)
  }</div>
))

export const B4BackgroundSwarm = memo(() => (
  <div className="fixed inset-0 z-[-1]">
    <BackgroundAnimation/>
  </div>
))